import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import GemBullet from '../components/GemBullet';
import Action from '../components/Action';
export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        promo
        pic
      }
      fields {
        name
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Mastery through Play",
  "date": "2017-11-25",
  "promo": "grids",
  "description": "The most effective way to learn",
  "pic": "/img/mastery-through-play2.jpg",
  "cover": "/img/cover/mastery-through-play2.jpg",
  "color": "#ef842c"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`A few years ago I was in a haze. I'd often find myself staring blankly at my screen at work. One afternoon I was supposed to be working on a form (ugh, `}<em parentName="p">{`forms`}</em>{` right?) for a super lame work project that I didn't care for `}<em parentName="p">{`at all`}</em>{`.`}</p>
    <p>{`I was bored out of my mind.`}</p>
    <p>{`I decided then and there that I needed a new job. That evening I started brushing up my résumé. It was an Apple Pages document and I was having some trouble getting the formatting to line up exactly how I wanted. After enough frustration with the blasted thing I said to myself "bah! I'll just do this with CSS." (famous last words, right?)`}</p>
    <p>{`I took the next day off work, strolled past the "no loitering" sign and posted up at the local McDonald's. I had everything I needed in order to create a flashy new web-based résumé: my laptop, charger, and an infinite supply of free-flowing Diet Coke.`}</p>
    <p>{`While I worked, I noticed something both annoying and interesting. I was spending a `}<em parentName="p">{`lot`}</em>{` of my time at the css-tricks `}<em parentName="p">{`Complete Guide to Flexbox`}</em>{` site. I stopped and thought for a second. "Wait... `}<strong parentName="p">{`why do I `}<em parentName="strong">{`still`}</em>{` have to look this stuff up`}</strong>{`? I've built like six projects already using Flexbox". I slogged through it and at length finished my new résumé.`}</p>
    <p>{`I sent it out to a couple of companies that looked fun to work for but my mind remained fixed on the Flexbox question. At work the next day I decided to cowboy up and get the form over with. The company had recently - `}<em parentName="p">{`finally`}</em>{` - dropped IE9 support (enterprise amiright?) so I tackled it with Flexbox, scoring some more traffic/ad revenue for the css-tricks guys. After trying random, failing combinations of `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{`, `}<inlineCode parentName="p">{`width`}</inlineCode>{`, and `}<inlineCode parentName="p">{`flex-shrink`}</inlineCode>{` `}<em parentName="p">{`again`}</em>{` I had had enough.`}</p>
    <p><em parentName="p">{`"Davie my boy"`}</em>{` — I said to myself — `}<em parentName="p">{`"you need to actually learn Flexbox"`}</em>{`.`}</p>
    <p>{`I dove head first into the `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/css-flexbox-1/"
      }}>{`CSS Flexible Box Layout Module`}</a>{` spec. I read it all the way through - with a growing sense of dread that I had made a terrible life decision to become a UI dev. I might as well have read a manual on Thermodynamics for all I got out of it.`}</p>
    <p>{`Morale only mostly shattered, I headed over to codepen and started playing around with Flexbox. I experimented, tweaked, watched the changes as I made them. I wrote down any questions that came to mind while I tinkered. And gradually things started to `}<em parentName="p">{`click`}</em>{`. I became `}<em parentName="p">{`enamoured`}</em>{` with Flexbox. Most days I'd try to get my work done extra fast so I'd have some time to `}<strong parentName="p">{`play`}</strong>{` with it, to learn even more.`}</p>
    <p>{`I went back and re-read the Flexbox spec and this time it made perfect sense. The craziest part is I found `}<strong parentName="p">{`I was actually beginning to enjoy my work again`}</strong>{`. I didn't realize it at the time but I had accidentally discovered a critical aspect to loving work.`}</p>
    <p>{`I started getting requests from coworkers to help them with Flexbox. So I shared the metaphors for understanding Flexbox concepts that I had come up for myself for remembering things:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Picture you're holding a crossbow and aiming either North, East, South, or West. That's `}<inlineCode parentName="p">{`flex-direction`}</inlineCode>{`. These zombies (flex items) are coming from the south, so set your crossbow to `}<inlineCode parentName="p">{`flex-direction: column`}</inlineCode>{`.`}</p>
    </blockquote>
    <p>{`The people I helped `}<em parentName="p">{`loved`}</em>{` this way of thinking about Flexbox. They convinced me to create a little tutorial on it. Flexbox Zombies v1 was born.`}</p>
    <p>{`It started as a little prototoype. I made a simple text-based email sequence with some codepen exercises that looked like this:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/fbz-prototype.jpg",
        "alt": "flexbox zombies prototype"
      }}></img></p>
    <p>{`I launched the project and refreshed my email list frequently to see if anybody on the interwebs had signed up. Pretty much just my friends and people from work, but oh well I thought, it was fun to make.`}</p>
    <p>{`One morning I checked and saw that three hundred people had signed up. I was shocked and ecstatic. It turned out some of my popular friends (`}<a parentName="p" {...{
        "href": "https://twitter.com/kentcdodds"
      }}>{`Kent`}</a>{`, `}<a parentName="p" {...{
        "href": "https://twitter.com/iammerrick"
      }}>{`Merrick`}</a>{`) had shared my creation on Twitter. I had been wanting to do my own thing for years, had even stockpiled a decent savings but was still `}<a parentName="p" {...{
        "href": "/post/overcoming-fear-and-doing-your-own-thing/"
      }}>{`living in fear`}</a>{` — too afraid to make the leap. But this small win was all the excuse I needed. I put in my two weeks notice and set out on my own to create the full on `}<a parentName="p" {...{
        "href": "https://flexboxzombies.com"
      }}>{`Flexbox Zombies`}</a>{` Mastery Game:`}</p>
    <p><img parentName="p" {...{
        "src": "/gif/fbz.gif",
        "alt": "flexbox zombies creation"
      }}></img></p>
    <p>{`I decided to keep this game free, with the hopes that people would love it and would want to support me by buying my next game. To date over 80,000 people have signed up and learned Flexbox with me. `}</p>
    <p>{`After shipping that I announced a preorder for my first paid game: `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{` which helps people internalize CSS Grid layout.`}</p>
    <p><img parentName="p" {...{
        "src": "/gif/gc.gif",
        "alt": "grid critters creation"
      }}></img></p>
    <p>{`To my utter delight, hundreds of devs dropped their hard-earned cash monies on this new Mastery Game. Including engineers from Microsoft, Github, Google and Apple. I was completely floored. But what's awesome about all of this isn't `}<em parentName="p">{`me`}</em>{` — but the highly valuable principle I discovered along the way.`}</p>
    <h2>{`Mastery through Play`}</h2>
    <p>{`By facing down the Flexbox dragon I inadvertently stumbled onto a key principle of learning: `}<strong parentName="p">{`even the most difficult topics can be mastered through play`}</strong>{`. Learning through play is more fun, easier to get yourself to actually do, and much more effective.`}</p>
    <p>{`Don't `}<em parentName="p">{`coast`}</em>{`. Not ever really mastering your craft is `}<em parentName="p">{`easy`}</em>{`. But it's numbing. It's like never venturing out of a game's starting zone. No wonder you're bored out of your mind fighting level 1 sheep. That's all you're capable of handling to this point, but you were made for so much more.`}</p>
    <p>{`So here's my advice: start `}<strong parentName="p">{`playing`}</strong>{`. Face the difficulty head on. Commit to `}<strong parentName="p">{`mastering`}</strong>{` frontend development. It `}<em parentName="p">{`will`}</em>{` be hard. Head down this path and yes - there be dragons. But you'll slay them and it will feel amazing. And a few things will happen:`}</p>
    <GemBullet mdxType="GemBullet">
  You'll be able to build things you never imagined you could.
    </GemBullet>
    <GemBullet mdxType="GemBullet">
  Your tools will become second nature, an extension of yourself.
    </GemBullet>
    <GemBullet mdxType="GemBullet">
  The challenges you face will shift: becoming less about the tools and tech you
  use, and more about what you can create with them.
    </GemBullet>
    <GemBullet mdxType="GemBullet">
  You'll start landing the best kinds of projects: <em>hard but fun</em>. The type of work that's both financially and emotionally rewarding.
    </GemBullet>
    <GemBullet mdxType="GemBullet">
  You'll enter and remain longer in the productive, peaceful state of flow.
    </GemBullet>
    <GemBullet mdxType="GemBullet">
  Best of all, you'll <strong>love your work.</strong>
    </GemBullet>
    <h2>{`Your Journey Begins`}</h2>
    <p>{`I've designed `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{` specifically for you. Because you're like me — you're not content to be mediocre in your craft. Devs like us have too much passion to settle or coast.`}</p>
    <p>{`It's time to `}<strong parentName="p">{`level up`}</strong>{` your frontend coding skills. Leverage this `}<em parentName="p">{`mastery through play`}</em>{` principle with my Mastery Game. It will guide you, challenge you, reward you. It's the ideal environment for `}<em parentName="p">{`playing`}</em>{` with CSS Grid. One where you get to actually `}<a parentName="p" {...{
        "href": "/post/grid-item-placement/"
      }}>{`see how the CSS spec works`}</a>{`. By the time you complete the hundreds of levels, you `}<em parentName="p">{`will`}</em>{` be a master of CSS Grid layout.`}</p>
    <Action link="https://gridcritters.com" mdxType="Action">Master CSS Grid now</Action>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      